<template>
  <v-container fluid class="pa-0 ma-0">
    <v-toolbar dense flat>
      <!-- 주간보기선택 -->
      <div class="hidden-xs-only" style="width: 90px">
        <v-select
          v-model="weekdays"
          :items="weekdaysOptions"
          label=""
          hide-details
          menu-props="auto"
          class="pa-0"
          dense
          style="font-size: 0.785rem !important"
        ></v-select>
      </div>

      <!-- 이전 버튼 -->
      <v-btn
        icon
        small
        class="ml-1"
        color="info darken-1"
        @click="prev"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <!-- 날짜 타이틀 표시 영역: 글자는 computed.title() 에서 제어함. 클릭시 작은 선택 달력을 띄운다 -->
      <v-menu
        ref="dateSelector"
        v-model="dateSelector"
        :return-value.sync="focusedMonth"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            class="text-h6 mx-1"
            color="info darken-1"
            :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-2 mx-0' : 'text-h6 mx-1'"
            style="cursor: pointer;"
          >
            {{ title }}
          </v-btn>
        </template>
        <v-date-picker
          ref="picker"
          v-model="focusedMonth"
          type="month"
          no-title
          scrollable
          locale="ko"
          active-picker.sync="MONTH"
          :max="maxYear"
          min="1980-01"
          @change="dateSelect"
        ></v-date-picker>
      </v-menu>

      <!-- 다음 버튼 -->
      <v-btn
        icon
        small
        class="mr-2"
        color="info darken-1"
        @click="next"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

      <!-- 이번달/오늘 버튼 -->
      <v-btn
        v-model="search.today"
        text
        small
        class="hidden-xs-only px-1 mx-1"
        @click="btnClick('today')"
      >
        <v-icon small
          :color="search.today ? 'primary' : ''"
        >mdi-calendar</v-icon>
        <span>이번달</span>
      </v-btn>
      <!-- 사용안함: 중요 버튼 -->
      <!-- <v-btn
        v-model="search.star"
        text
        small
        class="hidden-xs-only px-1 mx-1"
        @click="btnClick('star')"
      >
        <v-icon small
          :color="search.star ? 'amber darken-1' : ''"
        >mdi-star</v-icon>
        <span>중요</span>
      </v-btn> -->
      <!-- 담당부서 -->
      <div class="hidden-xs-only" style="width:150px">
        <v-select
          v-model="search.ss2"
          :items="select.ss2"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mx-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss2')"
        ></v-select>
      </div>
      <!-- !! 회의실 셀렉트(ss3): 회의실유무로 판단 -->
      <template v-if="isMeetingRoom">
        <div style="width:180px">
          <v-select
            v-model="search.ss3"
            :items="select.ss3"
            label=""
            item-value="value"
            item-text="text"
            hide-details
            menu-props="auto"
            class="pa-0 mx-1"
            dense
            style="font-size: 0.785rem !important"
            @change="selectChange('ss3')"
          ></v-select>
        </div>
        <!-- !![2022.7.27] 회의실 목록 팝업 띄우는 버튼 -->
        <v-hover v-slot:default="{ hover }">
          <v-btn
            small
            depressed
            color="light-green lighten-4"
            class="hidden-xs-only px-1 mx-1"
            @click="meetRoomPopOpen"
          >
            <v-icon
              :color="hover ? 'pink' : 'teal'"
              class="mr-1"
              style="font-size: 1.25rem; font-weight: 550;"
            >mdi-billboard</v-icon>
            <span>회의실정보</span>
          </v-btn>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-btn
            small
            text
            class="pl-1 pr-3 mx-1"
            :color="hover ? 'primary' : ''"
            @click="meetingReservPop(null)"
          >
            <v-icon
              :color="hover ? 'pink' : ''"
              class="mr-1"
            >
              mdi-plus-circle-outline
            </v-icon>
            <span>예약</span>
          </v-btn>
        </v-hover>
      </template>
      <template v-else>
        <v-btn depressed text color="error">
          <v-icon small color="warning">mdi-alert</v-icon>
          회의실이 없습니다. 회의실을 생성한 후 진행하시기 바랍니다.
        </v-btn>
      </template>

      <v-spacer></v-spacer>

      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen2"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
    </v-toolbar>

    <v-toolbar dense flat color="grey lighten-2" class="hidden-xs-only">
      <div class="text-left mr-0">
        <!-- <v-btn
          v-show="isMeetingRoom"
          fab x-small dark depressed
          color="lime darken-3"
          class="mx-2"
          @click="meetingReservPop"
        >
          <v-tooltip bottom color="lime darken-3">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-plus</v-icon>
            </template>
            <span>예약</span>
          </v-tooltip>
        </v-btn> -->
        <v-btn text icon
          @click="setInitial"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-refresh</v-icon>
            </template>
            <span>초기화</span>
          </v-tooltip>
        </v-btn>
      </div>
      <div class="text-left hidden-xs-only">
        <v-chip
          v-for="(item, i) in searchKeywords"
          :key="i"
          small
          :close="!item.isEver"
          color="grey lighten-4"
          class="mr-1"
          @click:close="closeSearchKeyword(item)"
        >{{ item.text }}</v-chip>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card
      tile
      :elevation="0"
      color="primary"
      class="py-1"
    >
      <v-sheet class="pb-12">
        <v-calendar
          ref="calendar"
          v-model="focusedDay"
          type="month"
          locale="ko"
          :weekdays="weekdays"
          :now="nowToday"
          :day-format="timestamp => new Date(timestamp.date).getDate()"
          @change="updateRange"
        >
          <!-- !! day-label 슬롯 : 월간일정의 날짜 표기를 담당한다 -->
          <template v-slot:day-label="label">
            <v-hover v-slot:default="{ hover }">
              <div
                class="text-caption text-start pl-1"
                :style="hover ? 'text-decoration: underline;' : ''"
              >
                <!-- 과거(오늘 이전)인 경우 날짜색을 회색으로 한다. 클릭도 안됨 -->
                <template v-if="label.past">
                  <v-chip
                    x-small
                    color="white"
                    class="px-1 grey--text text--lighten-1"
                    style="font-size: 0.75rem;font-weight: 400;"
                  >
                    {{ label.day }}
                  </v-chip>
                  <span class="error--text text--lighten-3 pl-1" style="font-weight: 700;letter-spacing: 0.08em;">{{ holyDay[label.date] }}</span>
                </template>
                <template v-else>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-chip v-if="label.present"
                        v-on="on"
                        x-small
                        color="primary"
                        class="px-1"
                        style="cursor: pointer;font-size: 0.75rem;font-weight: 400;"
                        @click="meetingRsrvByDate(label.date)"
                      >
                        {{ label.day }}
                      </v-chip>
                      <v-chip v-else
                        v-on="on"
                        x-small
                        :color="hover ? 'yellow lighten-3' : 'white'"
                        class="px-1"
                        style="cursor: pointer;font-size: 0.75rem;font-weight: 400;"
                        @click="meetingRsrvByDate(label.date)"
                      >
                        {{ label.day }}
                      </v-chip>
                    </template>
                    <span>예약</span>
                  </v-tooltip>
                  <span class="error--text pl-1" style="font-weight: 700;letter-spacing: 0.08em;">{{ holyDay[label.date] }}</span>
                </template>
              </div>
            </v-hover>
          </template>
          <!-- !! day slot : 월간모드 > 데이터 뿌리기 -->
          <template v-slot:day="{ date }">
            <v-row
              no-gutters
              class="ma-0 pa-0 text-caption d-block"
            >
              <v-card
                tile
                elevation="0"
                min-height="80"
                class="pa-0 text-caption"
                :class="monthPastColor(date)"
              >
                <template v-if="datas[date]">
                  <div
                    class="px-1 pa-1 text-start"
                    v-for="item in datas[date]"
                    :key="item.id"
                    style="cursor: pointer;"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <!-- 중요: 아래 div에 class="text-truncate" 을 붙이면 말줌임표를 넣을 수 있다! -->
                      <div
                        class="pa-1 mb-0"
                        @click.stop="meetingReservPop(item)"
                        style="font-size: 0.80rem;font-weight: 500;letter-spacing: 0.03333em;line-height: 1.6;"
                        :style="hover ? 'background-color: #F3FBFE;' : ''"
                      >
                        <template v-if="item.teamName === '***'">
                          <div
                            class="grey--text"
                            v-html="item.str4"
                          ></div>
                          <v-icon small class="mr-1" color="grey">
                            mdi-cancel
                          </v-icon>
                          <span
                            class="grey--text"
                            style="font-size: 0.8rem;font-weight: 400;"
                          >{{ item.wname }}</span>
                        </template>
                        <template v-else>
                            <div
                            :class="hover ? 'info--text text--darken-1' : 'indigo--text text--darken-1'"
                            v-html="item.str4"
                          ></div>
                          <span
                            class="mr-1"
                            style="font-size: 0.82rem;font-weight: 400;"
                          >[{{ item.teamName }}]</span>
                          <span
                            class="primary--text text--lighten-1"
                            style="font-size: 0.8rem;font-weight: 400;"
                          >{{ item.wname }}</span>
                        </template>
                      </div>
                    </v-hover>
                  </div>
                </template>
              </v-card>
            </v-row>
          </template>
        </v-calendar>
      </v-sheet>
    </v-card>

    <!-- !! overlay -->
    <v-overlay :value="overlay" opacity="0.25">
      <v-progress-circular indeterminate size="70" color="primary"></v-progress-circular>
    </v-overlay>

    <!-- !! 회의실 목록 팝업 -->
    <meet-room-pop ref="meetRoomPop"></meet-room-pop>
    <!-- !! 회의실 예약 팝업 -->
    <meeting-reserv-pop ref="meetingReservPop"></meeting-reserv-pop>
    <!-- !! 독립메뉴 업무 등록 다이얼로그 참 -->
    <upmu-add-dialog ref="upmuAddDialog"></upmu-add-dialog>

  </v-container>
</template>

<script>
// @: sleep
import sleep from '@/lib/sleep'

// @: 공휴일 저장 파일
import holyday from '@/lib/holyday'

// // @: filters
// import cutString from '@/filters/cutString'
// import numberFormat from '@/filters/numberFormat'
// import strDateFormat from '@/filters/strDateFormat'
// import strDateFormat2 from '@/filters/strDateFormat2'
// import strDateFormat3 from '@/filters/strDateFormat3'

// @: 회의실 목록 팝업
import meetRoomPop from '@/views/meeting/meetRoomPop'
// @: 회의실 예약 팝업
import meetingReservPop from '@/views/meeting/meetingReservPop'
// @: 독립메뉴 업무 팝업
import upmuAddDialog from '@/components/UpmuAddDialog'

// @: pdfmake view
import {
  pdfMake,
  pdfViewStyle
} from '@/lib/pdfmakeView'

// 주간 요일 기본값: 월 ~ 금
const weekdaysDefault = [1, 2, 3, 4, 5]

// 주간 요일 전체값: 일 ~ 토
const weekdaysAll = [0, 1, 2, 3, 4, 5, 6]

export default {
  components: {
    meetRoomPop,
    meetingReservPop,
    upmuAddDialog
  },

  data: () => ({
    datas: {}, // 넘어온 데이터를 패칭할 변수 - !! 리스트와 다르게 배열이 아니라 객체다
    holyDay: {}, // 공휴일 객체 - import 한 holyday 객체를 이변수에 매칭해야 한다!
    focusedMonth: new Date().toISOString().substr(0, 7), // * 달력 피커에 선택된 현재 월. v-date-picker.v-model 의 값
    focusedDay: '', // * 현재월로 선택된 달의 1일. calendar.v-model 의 값
    nowToday: '', // * 실제 오늘(즉, 현재)
    startDay: null, // data 패칭의 시작일
    endDay: null, // data 패칭의 마지막일
    dateSelector: false, // 날짜 셀렉트 메뉴 -- 연월(일) 타이틀을 클릭시 작은 달력 메뉴가 나오게 하는 결정인자
    start: {
      date: '',
      year: 0,
      month: 0,
      day: 0
    },
    end: {
      date: '',
      year: 0,
      month: 0,
      day: 0
    },
    weekdays: weekdaysDefault,
    weekdaysOptions: [
      { text: '월 ~ 금', value: weekdaysDefault },
      { text: '일 ~ 토', value: weekdaysAll }
    ],
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체(검색용)
    params: { // 검색용 인자 객체
      draw: 0,
      where: {
        startDay: '',
        endDay: '',
        focusedMonth: '', // 선택월
        today: true, // 오늘 검색 버튼 초기값
        star: false,
        ss3: '' // 회의실
      },
      sort: [ 'date1' ], // 업무일순 정렬 주의: 정렬의 갯수만큼 초기값 지정해야 함
      order: [ 'ASC' ] // 달력에선 내림차순이 아니다!
    },
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    search: {
      today: true, // 오늘 검색 버튼 클릭값 - true/false
      star: false, // 중요 검색 버튼 클릭값 - true/false
      ss2: '', // [담당부서] 셀렉트 선택값
      ss3: '' // [회의실] 셀렉트 선택값
    },
    // 구분: 정렬에 필요한 변수들 - 초기화 필요
    sort: {
      default: 1
    },
    // 구분: 셀렉트 객체들
    select: {
      ss2: [ // 담당부서 셀렉트  - 나머지는 카테고리에서 불러온다
        { text: '담당부서', value: '' }
      ],
      ss3: [ // 회의실 셀렉트
        // { text: '', value: '' } // * 없으면 경고가 뜨기 때문에 일단 빈값으로 준다
      ]
    },
    // 구분: 검색어 칩을 사용하는 것들의 배열 - 검색어 칩을 사용하는 요소를 명확히 알 수있다.
    // 타입: 정렬/단일검색/다중검색(배열) - sort/search/arrsearch
    // 이름: 요소이름 - sort.defalut
    // isArr: 체크박스 처럼 값이 배열로 넘어가는 경우
    // select: 비어있지 않은 경우 해당 셀렉트를 이용한다.
    // showSelect: 검색어 검색처럼 셀렉트를 직접 사용하진 않지만 텍스트를 보여줄 경우 사용(select: '' 인 경우에 주로 사용)
    // isEver: true 인 경우 항시 보여야 한다.
    // loading: true 인 경우 로딩시 처리된다
    useSearchKeywords: [
      // { type: 'search', name: 'ss1', select: 'ss1', showSelect: '', isEver: false, loading: true }
      { type: 'search', name: 'ss2', isArr: false, select: 'ss2', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss3', isArr: false, select: 'ss3', showSelect: '', isEver: true, loading: false }
    ],
    // 구분: 검색어 칩 배열을 위한 변수
    searchKeywords: [],
    // 구분: 오버레이용 변수
    overlay: false,
    // 구분: 기타 변수들
    timeout: null, // delay() 에서 사용하는 변수 - 초기화 불필요
    // 구분: 한글 요일(일간일정에서 쓰인다)
    yoilHan: ['일', '월', '화', '수', '목', '금', '토'],
    // 구분: 회의실 존재유무 - 없으면 false
    isMeetingRoom: false,
    // 구분: 회의실정보 팝업에 전달해 주는 회의실 정보
    meetroomList: [],
    // 구분: pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      // pageOrientation: 'landscape', // 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 10, 20, 10, 20 ],
      content: []
    }
  }),

  computed: {
    // * 달력피커의 최대 선택년도
    // * 주의: 2022-01 처럼 년-월 패턴으로 해야함
    maxYear () {
      return this.$moment().add(3, 'years').format('YYYY-MM')
    },
    // * 툴바 타이틀
    title () {
      // 시작일과 마지막일 -> @change="updateRange" 에 의해 지정됨
      const { start } = this
      if (!start) return ''

      const startYear = start.year
      const startMonth = start.month

      return `${startYear}년 ${startMonth}월`
    }
  },

  watch: {
  },

  created () {
    this.setInitDateConfig() // * 초기 필수 일자 세팅 호출

    // ! 중요: import 한 holyday(공휴일) 객체를 지역변수에 담아야 한다
    this.holyDay = holyday

    // ! 중요: 우선순위 때문에 created 에서 호출해야 한다!
    this.loadSchedule()
  },

  mounted () {
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 팀정보 에러시 처리하는 함수 - overlay 같은게 여기엔 없다
    async popTeamError (msg) {
      try {
        // 팝업 에러창
        const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        if (await pop.open('접근권한', msg, { color: 'warning', width: 400 })) {
          this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
        } else {
          this.updateRange({ start: this.start, end: this.end }) // !! 달력 강제 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 초기화시 필수로 지정해야 하는 것
    // ? created(), initVals() 에서 사용한다.
    setInitDateConfig () {
      try {
        // * v-date-picker 용: 선택월을 지정한다. - 초기 로딩이므로 현재월로 세팅한다.
        this.focusedMonth = this.$moment(new Date()).format('YYYY-MM')

        // * v-calendar 용: 선택월의 '01'일을 선택일로 세팅한다.
        this.focusedDay = `${this.focusedMonth}-01`

        // * 오늘을 nowToday 에 저장한다. 이 변수는 오직 '오늘'만 저장한다.
        this.nowToday = this.$moment(new Date()).format('YYYY-MM-DD')

        // * 초기값(월~금)으로 세팅하기
        if (!this.weekdays) {
          this.weekdays = weekdaysDefault
        }

        // * this.start, this.end 의 초기값 세팅하기
        this.setStartEndThisMonth()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 회의실 목록 팝업 띄우기
    // * [2022.9.16] 선택한 회의실로 리스트 리프레시 기능을 주었다.
    async meetRoomPopOpen () {
      try {
        // open dialog
        const result = await this.$refs.meetRoomPop.open({ width: 750, height: 950 }, this.meetroomList)
        // * 넘어온 회의실 아이디가 있고 & 현재 회의실이 아닌 경우 리프레시 시킨다.
        if (result > 0 && this.search.ss3 !== result) {
          this.search.ss3 = result
          // 아래 함수로 리프레시와 검색어 침도 같이 처리한다.
          this.selectChange('ss3')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 회의예약(등록/수정) 팝업 띄우기
    async meetingReservPop (meeting) {
      try {
        // * 현재 선택된 회의실
        const meetroom = this.meetroomList.find(mr => mr.id === this.search.ss3)

        if (meeting) { // #1. 수정
          // * 수정시에는 meeting.day 가 오늘 이전인지 여부를 체크해서 넘겨야 한다. isPast = true 면 과거인 것이다.
          let isPast = false
          if (this.$moment(meeting.day).startOf('day').diff(this.$moment(), 'days') < 0) {
            isPast = true
          }

          const result = await this.$refs.meetingReservPop.open('예약수정', { width: 600, height: 800 }, 1, isPast, meetroom, meeting, '')
          // console.log(result)
          if (result) {
            if (result.isSuccess) {
              this.updateRange({ start: this.start, end: this.end }) // * 달력 강제 리프레시
              if (result.addWorkContent) { // * 업무추가시
                // * 약간의 딜레이를 주고 업무추가창을 연다
                // * 등록이지만 null 대신 result.form 으로 넘어온 meeting 객체를 주어야 한다. 수정을 의미하는 -1 대신 index = 2 로 넘긴다.
                setTimeout(() => { this.$refs.upmuAddDialog.open('업무', { width: 650 }, 2, result.form) }, 300)
              }
            }
          }
          //
        } else { // #2. 등록
          const result = await this.$refs.meetingReservPop.open('예약등록', { width: 600, height: 800 }, -1, false, meetroom, null, '')
          if (result) {
            if (result.isSuccess) {
              this.updateRange({ start: this.start, end: this.end }) // * 달력 강제 리프레시
              if (result.addWorkContent) { // * 업무추가시
                // * 약간의 딜레이를 주고 업무추가창을 연다
                // * 등록이지만 null 대신 result.form 으로 넘어온 meeting 객체를 주어야 한다. 수정을 의미하는 -1 대신 index = 2 로 넘긴다.
                setTimeout(() => { this.$refs.upmuAddDialog.open('업무', { width: 650 }, 2, result.form) }, 300)
              }
            }
          }
        }
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 회의예약(등록만)시 날자를 지정하는 것
    async meetingRsrvByDate (rsrvDay) {
      try {
        // console.log(day) // '2022-09-16'
        // * 현재 선택된 회의실
        const meetroom = this.meetroomList.find(mr => mr.id === this.search.ss3)

        // * 회의 예약 팝업을 연다 - 등록만
        // * meetingReservPop() 과 다른점은 날짜를 넘겨준다는 점!
        const result = await this.$refs.meetingReservPop.open('예약등록', { width: 600, height: 800 }, -1, false, meetroom, null, rsrvDay)
        if (result) {
          if (result.isSuccess) {
            this.updateRange({ start: this.start, end: this.end }) // * 달력 강제 리프레시
            if (result.addWorkContent) { // * 업무추가시
              // * 약간의 딜레이를 주고 업무추가창을 연다
              // * 등록이지만 null 대신 result.form 으로 넘어온 meeting 객체를 주어야 한다. 수정을 의미하는 -1 대신 index = 2 로 넘긴다.
              setTimeout(() => { this.$refs.upmuAddDialog.open('업무', { width: 650 }, 2, result.form) }, 300)
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: this.start, this.end 의 이번달 초기값세팅
    async setStartEndThisMonth () {
      try {
        const nDate = new Date()
        const yy = nDate.getFullYear()
        const mm = nDate.getMonth()
        const firstDay = new Date(yy, mm, 1)
        const lastDay = new Date(yy, mm + 1, 0)

        this.start.date = this.$moment(firstDay).format('YYYY-MM-DD')
        this.end.date = this.$moment(lastDay).format('YYYY-MM-DD')
        this.start.year = this.end.year = yy
        this.start.month = this.end.month = mm + 1
        this.start.day = 1
        this.end.day = lastDay.getDate()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 월간 - this.start, this.end 변수 세팅
    async setStartEndMonth (date) {
      try {
        const nDate = new Date(date)
        const yy = nDate.getFullYear()
        const mm = nDate.getMonth()
        const firstDay = new Date(yy, mm, 1)
        const lastDay = new Date(yy, mm + 1, 0)

        this.start.date = this.$moment(firstDay).format('YYYY-MM-DD')
        this.end.date = this.$moment(lastDay).format('YYYY-MM-DD')
        this.start.year = this.end.year = yy
        this.start.month = this.end.month = mm + 1
        this.start.day = 1
        this.end.day = lastDay.getDate()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: datepicker 로 날짜를 선택한 경우
    dateSelect () {
      try {
        // this.setStartEndMonth(this.focusedMonth) // 사용안함: 불필요함 - updateRange() 2번 호출될 수 있다.
        // this.$refs.calendar.checkChange() // 사용안함: 불필요함 - updateRange() 2번 호출될 수 있다
        this.$refs.dateSelector.save(this.focusedMonth)

        // ! 중요: v-calendar 의 변경을 이끄는 부분. 아래처럼 하드코딩으로 세팅해줘야 함
        this.focusedDay = `${this.focusedMonth}-01`

        this.search.today = false // 오늘 선택을 무조건 없앤다
        // this.delay(300) // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 초기화 함수 - 순수하게 검색 변수만 초기화 시킴
    initVals () {
      try {
        return new Promise((resolve, reject) => {
          this.overlay = true

          this.setInitDateConfig() // * 초기 필수 일자 세팅 호출

          // 구분: params 로 백앤드로 전송되는 값
          this.params.draw = 0
          this.params.where = {
            startDay: '',
            endDay: '',
            focusedMonth: '',
            today: true,
            star: false,
            ss3: '' // 회의실 셀렉트 값
          }
          this.params.sort = [ 'date1' ]
          this.params.order = [ 'ASC' ] // !! 달력에선 내림차순이 아니다!

          // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
          this.search.today = true // 오늘 검색 버튼 클릭값 - true/false
          this.search.star = false // 중요 검색 버튼 클릭값 - true/false

          this.search.ss2 = '' // 담당부서 셀렉트 선택값
          this.search.ss3 = '' // # 회의실 셀렉트 선택값

          this.select.ss3 = [] // * 회의실 초기화
          this.meetroomList = [] // * 회의실 정보 목록 초기화

          // 구분: 정렬 기본값(this.params.sort, order와 연계됨)
          this.sort.default = 1

          // * 회의실 목록 패칭 - 일단 이 자리로 해보자.. DB를 자주 검색하는게 좀 걸림..
          this.getMeetRooms()
            .then(mr => {
              // * 갯수(cnt), 리스트(list), 셀렉트용패칭(meetrooms) 로 반환된다.
              if (mr && mr.cnt > 0) {
                this.isMeetingRoom = true // * 회의실이 있다

                this.select.ss3.push(...mr.meetrooms) // * 패칭해서 가져온 넘으로 셀렉트 세팅

                // ! 회의실 선택을 배열의 첫번째 값으로 세팅 - 회의실은 필수값이므로 아래는 무척 중요함
                this.search.ss3 = this.select.ss3[0].value
                this.params.where.ss3 = this.select.ss3[0].value

                this.meetroomList.push(...mr.list)

                // * 검색어칩 띄우기: 여기서는 로딩시 검색어 칩 중 '회의실'을 띄운다
                this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.name === 'ss3'))
              } else {
                // ? 회의실이 없는 경우 진행은 중지됨
                this.isMeetingRoom = false // * 회의실이 없다

                // this.select.ss3 = [{ text: '', value: '' }]
                this.search.ss3 = 0
                this.params.where.ss3 = 0

                // ? 회의실이 없는 경우 검색어 칩도 띄우지 않는다?
              }
            })

          // 구분: 검색어 칩 배열 초기화
          this.searchKeywords = []

          // 중요: 얘를 까먹으면 비동기 pending이 풀리질 않는구나!
          resolve(true)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 변수초기화 실행하고 그외 초기화 실행 - 기존 initVals() 의 역할
    async setInitial () {
      try {
        this.initVals().then((res) => {
          // 구분: 담당부서 select - 나의 팀목록 불러오기
          // 주의: initVals() 에서 1번만 호출하려 했으나 로딩시 저장된 것을 처리하는게 불가능해서 각각 나눴다!
          this.getTeams().then(teams => {
            if (teams && teams.length > 0) {
              this.select.ss2.push(...teams)
            }
          })

          this.updateRange({ start: this.start, end: this.end }) // 달력 강제 리프레시

          sleep(550).then(() => { this.overlay = false }) // shut off overlay
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 일정 초기 로딩시 실행하는 함수
    async loadSchedule () {
      try {
        this.initVals().then(() => {
          // console.log('In loadSchedule()')

          // 검색어 칩 띄우기
          this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))

          // 중요: 담당부서 검색어 칩 처리 - 원래 initVals() 에서 1번만 호출해 처리하려 했으나
          // 팀의 경우 DB에서 패칭해서 리스트를 뿌리는 방식이라 속도가 늦어져 비동기 식에선 제대로 매치가 안된다.
          // 하여, 불러 들인 후 순차적으로 처리하는 식으로 한다.
          this.getTeams()
            .then(teams => {
              if (teams && teams.length > 0) {
                this.select.ss2.push(...teams)
              }
            })
            .then(() => {
              if (this.search.ss2) { // 저장된 팀이 있으면 로딩!
                const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === 'ss2')
                const sel = this.select.ss2.find(k => k.value === this.search.ss2)
                let skw = { text: sel.text, type: kw.type, name: kw.name, isEver: kw.isEver, isArr: kw.isArr }
                this.searchKeywords.push(skw)
              }
            })

          sleep(550).then(() => { this.overlay = false }) // shut off overlay
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 회의실목록 패칭
    async getMeetRooms () {
      try {
        const { data } = await this.$axios.get('lawork/lwc/cabinetList')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // console.log(data)
        return { cnt: data.cnt, list: data.list, meetrooms: data.meetrooms }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 팀목록 패칭
    // * fe/src/views/schedule/list.vue 와 다르게 data.teams 만 리턴하도록 간략화함.
    // * data.teams 는 셀렉트를 위한 [{ text, value },] 배열임
    async getTeams () {
      try {
        const { data } = await this.$axios.get('lawork/case/getMyBasicTeamInfo')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        return data.teams
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 달력 변경 이벤트 핸들러
    async updateRange ({ start, end }) {
      try {
        this.overlay = true

        // * 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(500 - Math.floor(Math.random() * 300))
        // console.log('In updateRange()')

        // 참고: 넘어온 현재월의 시작일, 마지막일자 세팅
        // start, end 세팅 - 캘린더 이벤트로 넘어온 날짜객체(start, end)를 따로 변수에 담아준다.
        this.start = start
        this.end = end

        // 참고: 전체 검색범위 - 해당월의 prev,next month 의 낀 날짜 구하기 (데이터 패칭시 기준)
        this.startDay = this.start.date
        this.endDay = this.end.date
        if (this.start.weekday > 0) {
          this.startDay = this.$moment(this.start.date).add(`-${this.start.weekday}`, 'days').format('YYYY-MM-DD')
        }
        if (this.end.weekday < 6) {
          this.endDay = this.$moment(this.end.date).add(`${6 - this.end.weekday}`, 'days').format('YYYY-MM-DD')
        }

        // * 현재월인 경우 '이번달' 버튼을 선택한다.
        if (this.nowToday.substr(0, 7) === this.start.date.substr(0, 7)) {
          this.search.today = true
        }

        // ! 회의실이 없는 경우 진행은 중지됨
        if (this.isMeetingRoom) {
          // * 회의실이 있다 - 정상적으로 일정 검색 진행
          this.params.where.startDay = this.startDay
          this.params.where.endDay = this.endDay
          this.params.where.focusedMonth = this.focusedMonth // 현재 선택된 월
          this.params.where.today = this.search.today // 이번달
          this.params.draw++

          // * 비어있는 검색 변수 제거
          for (let key in this.params.where) {
            if (!this.params.where[key]) {
              delete this.params.where[key]
            }
          }
          // console.log(this.params.where)

          // ! DB 패칭
          const { data } = await this.$axios.post(`lawork/lwc/meetRsrvList`, this.params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          this.datas = data.rObj
          // console.log(this.datas)
          //
        } else {
          // * 회의실이 없다 - 일정 검색 중지
        }

        this.overlay = false
        // console.log('In updateRange() ... end')
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 이전 - 자동리프레시됨
    prev () {
      try {
        this.search.today = false // 오늘 선택을 무조건 없앤다
        this.$refs.calendar.prev()

        // !! 필수과정
        this.$nextTick(() => {
          // * v-date-picker 의 월간 선택을 아래처럼 강제로 맞춰줘야 함
          this.focusedMonth = this.focusedDay.substr(0, 7)
          this.setStartEndMonth(this.focusedMonth)
          // console.log(this.start)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 다음 - 자동리프레시됨
    next () {
      try {
        this.search.today = false // 오늘 선택을 무조건 없앤다
        this.$refs.calendar.next()

        // !! 필수과정
        this.$nextTick(() => {
          // * v-date-picker 의 월간 선택을 아래처럼 강제로 맞춰줘야 함
          this.focusedMonth = this.focusedDay.substr(0, 7)
          this.setStartEndMonth(this.focusedMonth)
          // console.log(this.start)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: month 모드에서 해당월이 아닌 경우 바탕색을 회색으로 변경
    monthPastColor (date) {
      if (!this.start) return ''
      return this.start.month !== Number(date.substr(5, 2)) ? 'grey lighten-4' : ''
    },
    // 구분: '이번달' 세팅 - 원래 '오늘'도 있었지만, 현재 달력은 월만 선택한다.
    setToday () {
      // this.start, this.end 를 세팅해주고
      this.setStartEndThisMonth()

      // 강제로 이번달, 오늘도 세팅한다
      this.focusedMonth = this.$moment(new Date()).format('YYYY-MM')
      this.focusedDay = `${this.focusedMonth}-01`

      // 오늘 버튼을 선택해준다
      this.search.today = true

      // * 달력 강제 리프레시
      this.updateRange({ start: this.start, end: this.end })
    },
    // 구분: 버튼[검색] - true/false 검색 버튼 처리 함수
    // * 버튼은 눈에 띄는 구분이 있기 때문에 검색어 태그 목록을 만들지 않는다
    async btnClick (elem) {
      try {
        // this.search[elem] = !this.search[elem]
        // this.params.where[elem] = this.search[elem]
        // this.updateRange({ start: this.start, end: this.end }) // 달력 강제 리프레시

        // 중요: 원래는 위의 3줄처럼 간단해야 하지만 달력의 특성상 오늘/이번달 버튼에 대한 특별한 처리가 필요하다.
        if (elem === 'today') { // '오늘/이번달'을 클릭한 경우
          // 같은 오늘을 여러번 클릭해도 변동은 없다.
          if (this.focusedMonth !== this.nowToday.substr(0, 7)) {
            this.search[elem] = !this.search[elem]
            this.params.where[elem] = this.search[elem]
            if (this.search[elem]) {
              this.setToday() // * setToday() 에서 리프레시 시킨다
            }
          }
        } else {
          // '오늘' 이외의 경우
          this.search[elem] = !this.search[elem]
          this.params.where[elem] = this.search[elem]

          // * 달력 강제 리프레시
          this.updateRange({ start: this.start, end: this.end })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [검색] - 셀렉트 검색 처리 메소드
    async selectChange (elem) {
      try {
        this.params.where[elem] = this.search[elem]

        // if (elem === 'ss2') {
        //   // 담당부서 선택 설정 저장
        //   storage.set('savedScheduleSs2', this.params.where[elem])
        // }

        // * 검색어 칩 처리 - 타입은 검색 - search && name = elem
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === elem)
        await this.setSearchKeywords(kw)

        // * 달력 강제 리프레시
        this.updateRange({ start: this.start, end: this.end })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색어칩 처리 메소드 모음
    // 로딩시 보여줄 검색어 칩을 처리하는 메서드
    async loadSearchKeywords (kw) {
      try {
        if (!Array.isArray(kw)) throw new Error('[Array Not Found]loadSearchKeywords(): 잘못된 변수전달 방식입니다.')
        kw.forEach(async (k) => {
          await this.setSearchKeywords(k)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:중요: 검색어 칩을 보여주는 처리를 하는 메서드
    async setSearchKeywords (kw) {
      try {
        // this.searchKeywords 배열에 등록될 객체의 뼈대
        let skw = { text: '', type: kw.type, name: kw.name, isEver: kw.isEver, isArr: kw.isArr }
        // console.log(skw)

        // 기존 같은 타입과 이름의 배열이 있으면 삭제
        const index = this.searchKeywords.findIndex(k => k.type === kw.type && k.name === kw.name)
        if (index > -1) {
          this.searchKeywords.splice(index, 1)
        }

        // 현재값
        const currVal = this[kw.type][kw.name] || ''

        if (kw.isArr) { // 구분: 다중검색(배열검색) 인 경우
          if (!Array.isArray(currVal)) throw new Error('[Array Not Found]setSearchKeywords(): 잘못된 변수전달 방식입니다.')
          let txtArr = []
          // select 를 사용하는 경우 this.select[kw.select] 로 해당 값을 조회한다.
          if (kw.select) {
            for (let cv of currVal) {
              txtArr.push(this.select[kw.select].find(k => k.value === cv).text)
            }
          } else {
            // @: 아직 쓰이는데가 없음
            // select 를 사용하지 않는 경우 currVal 을 순회하면서 값을 바로 매칭
            for (let cv of currVal) {
              txtArr.push(cv)
            }
          }
          skw.text = txtArr.join(',')
          //
        } else { // 구분: sort, search 같은 단일 검색인 경우
          // select 가 있으면 select 에서 보여줄 text 를 가져온다
          if (kw.select) {
            const sel = this.select[kw.select].find(k => k.value === currVal)
            // console.log(sel)
            skw.text = (sel.value) ? sel.text : ''
          } else {
            // select 가 아닌 text 입력값은 현재값을 바로 매칭한다.
            // showSelect 가 지정된 경우 해당 셀렉트의 text 를 보여준다.
            if (kw.showSelect) {
              if (currVal) { // 값이 있어야 넣어준다
                skw.text = `${this.select[kw.showSelect].find(k => k.value === this.search[kw.showSelect]).text} - "${currVal}"`
              } else {
                skw.text = ''
              }
            } else {
              if (currVal) { // 값이 있어야 넣어준다
                skw.text = `"${currVal}"`
              } else {
                skw.text = ''
              }
            }
          }
          //
        }

        if (skw.text) {
          this.searchKeywords.push(skw)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 검색어 칩을 닫는 메서드
    async closeSearchKeyword (chip) {
      try {
        if (!chip.isEver) { // isEver = true 인 넘은 없앨 수 없다. false 인 경우만 처리
          const kw = this.useSearchKeywords.find(c => c.type === chip.type && c.name === chip.name)
          if (kw.select) {
            // 셀렉트 검색인 경우
            // this.select.sido = '' 처럼 셀렉트의 가장 처음값을 초기값으로 보고 변경시킨다.
            this[kw.type][kw.name] = this.select[kw.name][0].value
            await this.selectChange(kw.name)
          } else {
            //  검색어 검색인 경우
            if (kw.type === 'search' && kw.name === 'sw') {
              this[kw.type][kw.name] = ''
              await this.searchWord()
            }
          }
          // 중요: 일정에서만 - 검색어 칩을 닫을 때 검색셀렉트를 '내용'으로 초기화 시켜야 한다.
          // '업무자'를 선택한 경우 표시항목이 '업무'로 고정되므로 유저가 혼란을 느껴서..
          this.search.sf = 1
          this.showUpmuMessage = false // 업무자
          this.showAuthMessage = false // 비용/수금/물품 관리권한 메시지
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 생성 함수2 - 데이터 형
    async pdfgen2 () {
      try {
        this.overlay = true

        // * 현재 선택된 회의실
        const meetroom = this.meetroomList.find(mr => mr.id === this.search.ss3)
        // console.log(meetroom)

        // * pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: `${this.title} ${meetroom.name} 예약일정`,
          subject: `${this.title} ${meetroom.name} 예약일정`
        }

        // * 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfViewStyle // 중요: 일정은 리스트와 뷰 스타일 모두 사용
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // # 회의실 예약은 월간만 존재한다.

        // * 월간이면 가로로
        this.pdfDoc.pageOrientation = 'landscape'

        // @: 헤더 타이틀
        const pdfHeaderTitle = `${this.title} ${meetroom.name} 예약일정`
        // @: 작성일
        // const workDate = `작성: ${this.$moment().format('YYYY.MM.DD HH:mm')}`

        // 중요: this.$refs.calendar.$children[0].days 에 월간일정에 필요한 모든 일자 데이터가 들어있다.
        let monthContent = this.$refs.calendar.$children[0].days

        // * 테이블 껍데기
        let tbl = {
          // style: 'tableBody',
          layout: { // 중요: 테이블 레이아웃을 조절한다.
            // hLineWidth: function hLineWidth (i) { return 0 },
            // vLineWidth: function vLineWidth (i) { return 0 },
            paddingLeft: function (i, node) { return 5 },
            paddingRight: function (i, node) { return 5 },
            paddingTop: function (i, node) { return 5 },
            paddingBottom: function (i, node) { return 8 }
          },
          table: {
            widths: null,
            body: []
          }
        }

        // * this.weekdays 를 순회하면서 기본적인 테이블 헤더를 만든다.
        let tWidths = [] //
        let tHeaders = []
        this.weekdays.forEach(d => {
          // * td 의 갯수지정. 월 ~ 금, 일 ~ 토 여부에 따라 5일짜리, 7일 짜리로 분류된다.
          tWidths.push('*')
          // 테이블 헤더 만들기
          tHeaders.push({ text: this.yoilHan[d], style: 'tableHeader', alignment: 'center' })
        })
        tbl.table.widths = tWidths // td 갯수 와 넓이 대입
        tbl.table.body.push(tHeaders) // 테이블 헤더에 요일대입

        let tBody = [] // 각 tr 에 해당하는 내용
        const weekLen = this.weekdays.length // 한주의 길이. 5이거나 7

        // 중요: 모든 일자를 순회하면서 달력을 만든다.
        // 각 주의 마지막 요일(금 이거나 토)에 다다르면 새로운 주배열을 생성한다.
        // pdfmake 의 table 구조가 한 주씩 배열에 넣는 구조라 그에 맞게 생성한다.
        for (let i = 0; i < monthContent.length; i++) {
          let text = `${monthContent[i].day}\n` // 내용에 제일먼저 날짜를 넣는다.

          // * 해당 일자의 데이터가 있는 경우 패칭
          let cData = this.datas[monthContent[i].date]
          if (cData) {
            let n = 1
            let cLen = cData.length
            cData.forEach(item => {
              // text += JSON.stringify(item)
              // * text 에 내용을 만들어 넣는다. (소요시간, 팀명, 예약자명)
              text += `${item.str4}\n[${item.teamName}]${item.wname}`

              if (n < cLen) {
                text += `\n\n`
              }
              n++
            })
          }

          if (monthContent[i].month !== this.start.month) {
            // 이전달이거나 다음달인 경우 바탕색을 회색으로
            tBody.push({ text, style: 'tableBodyPast' }) // !! 내용을 넣는다.
          } else {
            // 현재달
            tBody.push({ text, style: 'tableBody' }) // !! 내용을 넣는다.
          }

          // 중요: 주의 끝(금요일 or 토요일)이면 개행(tr)을 한다.
          if ((i + 1) % weekLen === 0) {
            tbl.table.body.push(tBody) // 한주의 tr에 해당하는 tBody 배열을 테이블에 넣는다.
            tBody = [] // tBody 는 초기화 해야한다!
          }
        }
        // ? END OF FOR LOOP

        // * set content data
        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          tbl
        ]
        this.pdfDoc.content = content

        // * 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()

        // shut off overlay
        sleep(350).then(() => { this.overlay = false })
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/** 참고: 일정에서만 사용하는 스타일이라 공통으로 사용하는 App.vue 로 빼지 않았다. */
/* v-calendar 헤더 css 변경 */
.v-calendar-weekly__head {
  min-height: 30px;
  line-height: 100%;
  border-bottom: 1px solid #ededed;
}
/* 월간 일정 > 요일 - 폰트사이즈 11로 조정 원래 font-size는 10pt */
.v-calendar-weekly__head-weekday {
  padding: 10px 4px;
  font-size: 11pt;
  background-color: #fff;
}
/* 일간 > 요일 폰트 사이즈 12pt 로 조정 */
.v-calendar-daily_head-weekday {
  font-size: 12pt !important;
}
/* 일요일 ~ 토요일 폰트색상 - 원래 일,토요일의 폰트색상을 달리하려 하였으나 월~금 모드 사용 때문에 폐지 */
.v-calendar-weekly__head-weekday:nth-child(1) {
  color: #000000 !important; /*#ff5252 !important;*/
  caret-color: #000000 !important; /*#ff5252 !important;*/
}
.v-calendar-weekly__head-weekday:nth-child(2) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(3) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(4) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(5) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(6) {
  color: #000000 !important;
  caret-color: #000000 !important;
}
.v-calendar-weekly__head-weekday:nth-child(7) {
  color: #000000 !important; /*#1976d2 !important;*/
  caret-color: #000000 !important; /*#1976d2 !important;*/
}
/* 지난월,다음월 바탕색 */
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: #ffffff;
}
/* 지난월 요일색 */
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: #000000;
}
/* 주간,일간의 요일 색상을 검정으로 고정시킨다 */
.theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday,
.theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label {
  color: #000000 !important;
}
/* !! 좌측 헤더와 바디를 아예 없앤다 */
.v-calendar-daily__intervals-head {
  display: none;
}
.v-calendar-daily__intervals-body {
  display: none;
}
/* !! 얘만 없애면 interval 섹션이 전부 안보인다. 되는구나 */
.v-calendar-daily__body {
  display: none;
}
/* !! 주간/일간의 날짜라벨을 없앤다. */
.v-calendar-daily_head-day-label {
  display: none;
}
/* 억지로 바꾸는 방법은 있다만.. */
/* .v-calendar-daily__interval:nth-child(4) {
  height: 100px !important;
}
.v-calendar-daily__day-interval:nth-child(4) {
  height: 100px !important;
} */
/* .v-calendar-daily__intervals-body:not(.v-calendar-daily__interval) {
  height: 40px !important;
}
.v-calendar-daily__day.v-present:not(.v-calendar-daily__day-interval) {
  height: 40px !important;
} */
/* !! 이건 된다 */
/* .v-calendar-daily__day-interval {
  padding: 3px;
  height: auto !important;
  min-height: 40px;
  overflow: visible !important;
} */
</style>
